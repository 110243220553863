import React from "react"
import {createGame, loadGameState, sendInput} from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import assets from "../assets";

class ScreenInit extends React.Component {

    componentDidMount() {
        this.props.loadGameState()
            .then((result) => {
                if (result.error) {
                    if (result.payload.status === 404) {
                        this.props.createGame();
                    }
                }
            });
    }

    render() {
        return (
            <div className='overlay-layout screen-init'>
                <div className='overlay-layout__header'>
                    <div className='player-controls float-left'>{ this.props.modalToggle }</div>
                    <div className='player-controls'>{ this.props.fullscreenToggle() }</div>
                    <div className='player-controls'>
                        <div className="social">
                            <a href='https://www.facebook.com/jobventura.eu/' className='nav-link nav-icon' target='_blank'><i className="fab fa-facebook"/></a>
                        </div>
                    </div>
                    <div className='player-controls'>
                        <div className="social">
                            <a href='https://www.linkedin.com/company/jobventura' className='nav-link nav-icon' target='_blank'><i className="fab fa-linkedin-in"/></a>
                        </div>
                    </div>
                    <div className='clearfix'>&nbsp;</div>
                </div>
                <div className='overlay-layout__content'>
                    <div className='text-center text-white'>
                        <h1 className=''>JOBVENTURA</h1>
                        <h3 className=''>budování kariéry</h3>
                        <div className='d-inline-block mt-3' onClick={() => { this.props.sendInput({input: { type: 'next' }}) }}>
                            <div className="player-link__overlay__button">
                                <span className="nav-icon"><i className="fas fa-play"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overlay-layout__footer'>
                    <div>
                        <div className='ml-3'>VYTVOŘENO ZA PODPORY</div>
                        <div className=''><img src={assets.nadace_white} className='img-fluid' alt='Nadace Terezy Maxové dětem'/></div>
                    </div>
                    <div>
                        <div>SPONZOROVÁNO</div>
                        <div className='ml-2 mr-3'><img src={assets.accenture_white} className='img-fluid' alt='accenture'/></div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendInput: sendInput,
        createGame: createGame,
        loadGameState: loadGameState
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(ScreenInit));
