import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux';
import GameStateReducer from "./game-state-reducer";

const allReducers = combineReducers({
    router: routerReducer,
    gameState: GameStateReducer
});

export default allReducers
