import React from "react"
import {createGame, loadGameState, sendInput} from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class Checkpoint extends React.Component {

    render() {
        return (
            <div id='overlay-init'>
                {
                    this.props.debug &&
                    <div>
                        checkpoint<br/>
                        stats: { JSON.stringify(this.props.gameState.state.stats) }<br/>
                        <div><button onClick={() => { this.props.sendInput({input: { type: 'reinit_node' }}) }}>reinit_node</button></div>
                        <div><button onClick={() => { this.props.sendInput({input: { type: 'next' }}) }}>next</button></div>
                    </div>
                }
            </div>
        )
    };
};

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendInput: sendInput
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Checkpoint));