import React from "react"
import {createGame, loadGameState, sendInput} from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class ScreenLost extends React.Component {

    constructor(params) {
        super(params);
        this.state = {
            results: false
        }
    }

    showResults = () => {
        this.setState({
            results: true
        });
    };

    resultIcon(result) {
        switch(result) {
            case "negative": return (<i className='fas fa-frown'/>);
            case "neutral": return (<i className='fas fa-meh'/>);
            case "positive": return (<i className='fas fa-smile'/>);
            case "timeout": return (<i className='fas fa-stopwatch'/>);
        }
    }


    render() {
        let reload_btn = (this.props.gameState.properties.last_checkpoint) ? (
            <button className='btn btn-outline-primary mr-2' onClick={() => { this.props.sendInput({ input: { type: 'reload' } }) }}><span className='btn-icon'><i className='fas fa-redo'/></span> Hraj od posledího checkpointu</button>
        ) : '';

        const { gameState } = this.props;
        let answers = gameState.properties['answers'] || [];

        // console.log(gameState);

        if (!this.state.results) {
            return (
                <div className='overlay-layout screen-lost'>
                    <div className='overlay-layout__header'>
                        <div className='player-controls float-left'>{ this.props.modalToggle }</div>
                        <div className='player-controls'>{ this.props.fullscreenToggle() }</div>
                    </div>
                    <div className='overlay-layout__content text-center'>
                        <div className='clearfix'/>
                        <div>
                            <h3 className=''>PROHRA</h3>
                            <div className='mb-5'>
                                <p>Tentokrát, se ti to nepovedlo, možná si byl příliš zbrklý. Můžeš to ale zkusit znova.</p>
                            </div>
                            { reload_btn }
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-3'>
                                        <span className='btn btn-outline-primary' onClick={() => { this.props.createGame() }}>
                                            <span className='btn-icon'><i className='fas fa-play'/></span> HRAJ ZNOVU
                                        </span>
                                    </div>
                                    <div className='col-3'>
                                        <span className='btn btn-outline-primary' onClick={this.showResults}><i className='fas fa-list'/> VÝSLEDKY</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='overlay-layout__footer text-center'>
                        <div>
                            <h5 className=''>Sdílej hru ostatním:</h5>
                            <div className='social mb-2'>
                                <a href='https://www.facebook.com/jobventura.eu/' className='nav-link nav-icon m-2' target='_blank'><i className="fab fa-facebook"/></a>
                                <a href='https://www.linkedin.com/company/jobventura' className='nav-link nav-icon m-2' target='_blank'><i className="fab fa-linkedin-in"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='overlay-layout screen-results'>
                    <div className='overlay-layout__header'>
                        <div className='player-controls float-left'>{ this.props.modalToggle }</div>
                        <div className='player-controls'>{ this.props.fullscreenToggle() }</div>
                        <div className='player-controls'>
                            <div className="social">
                                <a href='https://www.facebook.com/jobventura.eu/' className='nav-link nav-icon' target='_blank'><i className="fab fa-facebook"/></a>
                            </div>
                        </div>
                        <div className='player-controls'>
                            <div className="social">
                                <a href='https://www.linkedin.com/company/jobventura' className='nav-link nav-icon' target='_blank'><i className="fab fa-linkedin-in"/></a>
                            </div>
                        </div>
                        <div className='player-controls float-left'><span className='btn btn-outline-primary' onClick={() => { this.props.createGame() }}><span className='btn-icon'><i className='fas fa-play'/></span> Hraj znovu</span></div>
                        <div className='clearfix d-lg-none'/>
                            <h4 className='text-center mt-lg-3 text-primary'>Výsledky tvojí jobventury:</h4>
                        <div className='clearfix'/>
                        <div className='container-fluid text-center mt-2 mb-3'>
                            <div className='row'>
                                <div className='col-4'>
                                    <strong>Body</strong><br/>
                                    { gameState.properties.stats.points }
                                </div>
                                <div className='col-4'>
                                    <strong data-toggle="tooltip" data-placement="top" title="Ne každý se dokáže prosadit a stát si za svým. Pokud máš pod 10 bodů, dávej si pozor, aby s tebou ostatní nezametli. Jestli máš 10 až 30, dokážeš se ozvat, když je potřeba. Jestli máš 30 a více, stojí za to se zamyslet, zda někdy není tvoje přímost na škodu">Popularita</strong><br/>
                                    { gameState.properties.stats.popularity }
                                </div>
                                <div className='col-4'>
                                    <strong data-toggle="tooltip" data-placement="top" title="Ne každý se dokáže prosadit a stát si za svým. Pokud máš pod 10 bodů, dávej si pozor, aby s tebou ostatní nezametli. Jestli máš 10 až 30, dokážeš se ozvat, když je potřeba. Jestli máš 30 a více, stojí za to se zamyslet, zda někdy není tvoje přímost na škodu">Asertivita</strong><br/>
                                    { gameState.properties.stats.assertiveness }
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'>
                            <div className='row font-weight-bold text-primary'>
                                <div className='col-2 text-center'>
                                    Scéna
                                </div>
                                <div className='col-8'>
                                    Popis
                                </div>
                                <div className='col-2 text-center'>
                                    Výsledek
                                </div>
                            </div>
                            <hr className='bg-white mb-0'/>
                        </div>
                    </div>
                    <div className='overlay-layout__content'>
                        <div className='container-fluid pt-3 result-list'>
                            {
                                answers.map((answer, index) => {
                                return (
                                    <div key={index}>
                                        <div className='row' key={index}>
                                            <div className='col-2 text-center'>
                                                <img src={`/thumb/${answer.video}.jpg`} className='img-fluid'/>
                                            </div>
                                            <div className='col-8'>
                                                <h5>{ answer.title }</h5>
                                                <div>{ answer.description }</div>
                                            </div>
                                            <div className='col-2 text-center'>
                                                { this.resultIcon(answer.result) }
                                            </div>
                                        </div>
                                        <hr className='bg-white op-25'/>
                                    </div>
                                )})
                            }
                        </div>
                    </div>
                    <div className='overlay-layout__footer'>

                    </div>
                </div>
            )
        }
    };
};

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState.state
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendInput: sendInput,
        createGame: createGame
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(ScreenLost));
