import React from "react"
import {IndexRoute, Route, Link, Switch, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {isMobile} from "react-device-detect";
import {createGame, loadGameState, sendInput} from "../actions";
import ScreenInit from "./screen-init";
import ScreenLost from "./screen-lost";
import ScreenWinning from "./screen-winning";
import NodesPickAnswer from "./node-pick-answer";
import Checkpoint from "./checkpoint";
import pic_1 from "../../images/authors/Pic_1.jpg";
import assets from "../assets";
import {Redirect} from "react-router";
import CloseOverlay from "./close-overlay";

class VideoPlayer extends React.Component {

    constructor(params) {
        super(params);

        this.state = {
            currentVideo: null,
            isFull: params.fullscreen,
            overlayStyle: { width: 0, height: 0 },
            videoState: null,
            playerSize: { width: 0, height: 0 },
            overlayPoster: new Image(),
            closeOverlay: false
        };
    }

    posterLoaded = () => {
        this.updateWindowDimensions();
    };

    componentWillReceiveProps(nextProps) {
        let currentVideo = null;

        if ( nextProps.gameState && nextProps.gameState.state ) {
            let videoId = nextProps.gameState.state['current_node']['properties']['video'];

            currentVideo = videoId ? `/video/${videoId}.mp4` : null;
        }

        this.setState({...this.state,
            isFull: nextProps.fullscreen,
            currentVideo: currentVideo,
            overlayStyle: this.getOverlayStyle()
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.currentVideo !== prevState.currentVideo) {
            this.videoElement.load();
            this.play();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props !== nextProps;
    }

    componentDidMount() {
        this.state.overlayPoster.onload = this.posterLoaded;
        this.state.overlayPoster.src = require('images/jobventura_poster.png');

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('fullscreenchange', this.updateWindowDimensions);
        this.videoElement.addEventListener('loadedmetadata', this.videoElementLoadedMetadata);
        this.videoElement.addEventListener('ended', this.videoEnded);
        this.videoElement.addEventListener('timeupdate', this.videoTimeUpdate);
        this.videoElement.addEventListener('pause', this.videoPaused());
        this.videoElement.addEventListener('playing', this.videoPlaying());

        document.body.onload = this.onLoad;
    }

    onLoad = () => {
        this.updateWindowDimensions();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('fullscreenchange', this.updateWindowDimensions);
        this.state.overlayPoster.removeEventListener('onload', this.posterLoaded);
        this.videoElement.removeEventListener('loadedmetadata', this.videoElementLoadedMetadata);
        this.videoElement.removeEventListener('ended', this.videoEnded);
        this.videoElement.removeEventListener('timeupdate', this.videoTimeUpdate);
        this.videoElement.removeEventListener('pause', this.videoPaused());
        this.videoElement.removeEventListener('playing', this.videoPlaying());
    }

    videoPaused = () => {
        this.setState({...this.state, videoState: 'paused'});
    };

    videoPlaying = () => {
        this.setState({...this.state, videoState: 'playing'});
    };

    videoElementLoadedMetadata = (data) => {
        this.updateWindowDimensions();
    };

    videoEnded = (data) => {
        this.props.sendInput({input: { type: 'next' }});
        this.setState({...this.state, videoState: 'ended'});

    };

    videoTimeUpdate = (data) => {
        // console.log('s');
        // console.log('Time Update');
        // console.log(this.videoElement.currentTime);
    };

    updateWindowDimensions = () => {
        this.setState({...this.state, playerSize: this.getPlayerSize(), overlayStyle: this.getOverlayStyle()});
    };

    getPlayerSize() {
        let videoRatio = 16/9;

        if (!this.videoContainerElement) return { width:0, height: 0}

        let width = this.videoContainerElement.offsetWidth;
        let height = this.videoContainerElement.offsetWidth * 9 / 16;

        if (height > window.innerHeight) {
            height = window.innerHeight;
        }

        return {
            width: width,
            height: height,
        };
    }

    play = () => {
        this.setState({ playPromise: this.videoElement.play() });
    };

    pause = () => {
        if (this.state.playPromise) {
            this.state.playPromise.then(() => {
                this.videoElement.pause();
            })
        } else {
            this.videoElement.pause();
        }
    };

    getOverlayStyle() {
        let videoRatio = 16/9;
        const playerSize = this.getPlayerSize();
        let containerRatio = playerSize.width / playerSize.height;

        let width = 0, height = 0, left = 0, top = 0, background = "inherit";

        if (videoRatio > containerRatio) {
            width = playerSize.width;
            height = playerSize.width * 9 / 16;
            top = ( playerSize.height - height ) / 2;
        } else {
            width = playerSize.height * 16 / 9;
            height = playerSize.height;
            left = ( playerSize.width - width ) / 2;
        }

        return {
            width: width,
            height: height,
            top: top,
            left: left
        };
    }

    fullscreenToggle = () => {
        return this.props.fullscreen ? <i className='control-item fas fa-compress' onClick={() => { this.props.goNormal() }}/> : <i className='control-item fas fa-expand' onClick={() => { this.props.goFullscreen() }}/>;
    };

    closeModalToggle = () => {
        this.setState({ closeOverlay: !this.state.closeOverlay });
    };

    modalToggle() {
        return <i className='control-item fas fa-times' onClick={() => { this.pause(); this.closeModalToggle() }}/>
    };

    render() {
        let containerClass = this.state.isFull ? 'fullscreen' : 'normal';

        let videos = [];

        if (this.state.currentVideo) {
            videos.push(this.state.currentVideo);
        }

        let sources = videos.map((url, index) => (
            <source key={index} src={url} type="video/mp4"/>
        ));

        const screenProps = {
            fullscreenToggle: this.fullscreenToggle,
            videoElement: this.videoElement,
            modalToggle: this.modalToggle(),
            pause: this.pause,
            play: this.play,
            sendInput: this.props.sendInput,
            debug: this.props.debug
        };

        return (
            <div id='video-container' className={containerClass} ref={(container) => { this.videoContainerElement = container; }}>
                <video id="video-player"
                       // muted="muted"
                       preload="auto"
                       width={this.state.playerSize.width}
                       height={this.state.playerSize.height}
                       ref={(video) => { this.videoElement = video; }}>
                    {sources}
                    <source src={assets.pic_1} type='image/png'/>
                </video>
                {   this.videoElement &&
                    <div id='video-player-overlay' style={this.state.overlayStyle}>
                        <Switch>
                            <Route path="/game/screen/init" exact render={() => (<ScreenInit {...screenProps}/>)}/>
                            <Route path="/game/nodes-pick-answer" exact
                                   render={(props) => (<NodesPickAnswer {...props} {...screenProps}/>)}/>
                            <Route path="/game/checkpoint" exact render={() => (<Checkpoint {...screenProps}/>)}/>
                            <Route path="/game/screen/lost" exact render={() => (<ScreenLost {...screenProps}/>)}/>
                            <Route path="/game/screen/winning" exact
                                   render={() => (<ScreenWinning {...screenProps}/>)}/>
                            <Redirect to={'/game/screen/init'}/>
                        </Switch>
                    </div>
                }
                { this.state.closeOverlay && <CloseOverlay createGame={this.props.createGame} closeVideoPlayer={this.props.close} closeModalToggle={this.closeModalToggle} {...screenProps}/> }
            </div>
        )
    };
};

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        createGame: createGame,
        loadGameState: loadGameState,
        sendInput: sendInput
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(VideoPlayer));