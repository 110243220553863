import React from "react"
import {createGame, loadGameState, sendInput} from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Progress} from "reactstrap";

class TimeProgress extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            time: 0,
            interval: null
        }
    }

    updateProgressBar = () => {
        this.setState({ time: this.props.videoElement.currentTime });
    };

    componentDidMount() {
        this.setState({interval: setInterval(this.updateProgressBar, 30)});
    }

    componentWillUnmount() {
        if (this.state.interval) clearInterval(this.state.interval);
    }

    render() {
        const { timeProgress } = this.props;
        if (timeProgress.show === 0 && timeProgress.hide === 0) return null;

        const progressState = this.state.time - timeProgress.show;
        const showProgressMax = timeProgress.hide - timeProgress.show;

        if (progressState <= 0 || progressState > showProgressMax) return null;

        return <Progress className='options-progress' value={progressState} max={showProgressMax}/>;
    }
}

class NodesPickAnswer extends React.Component {

    constructor(props) {
        super(props);

        const options = this.processOptions(props);

        this.state = {
            options: options,
            timeProgress: this.timeProgressOptions(options),
            videoState: null
        };
    }

    componentWillReceiveProps(nextProps) {
        const options = this.processOptions(nextProps);
        this.setState({
            ...this.state,
            options: options,
            timeProgress: this.timeProgressOptions(options)
        });
    }

    componentDidMount() {
        this.props.videoElement.addEventListener('timeupdate', this.videoTimeUpdate);
        this.props.videoElement.addEventListener('pause', this.videoPaused);
        this.props.videoElement.addEventListener('playing', this.videoPlaying);
        this.props.videoElement.addEventListener('ended', this.videoEnded);
    }

    componentWillUnmount() {
        this.props.videoElement.removeEventListener('timeupdate', this.videoTimeUpdate);
        this.props.videoElement.removeEventListener('pause', this.videoPaused);
        this.props.videoElement.removeEventListener('playing', this.videoPlaying);
        this.props.videoElement.removeEventListener('ended', this.videoEnded);
    }

    videoPlaying = () => {
        this.setState({...this.state, videoState: 'playing'});
    };

    videoPaused = () => {
        this.setState({...this.state, videoState: 'paused'});
    };

    videoTimeUpdate = (data) => {
        let options = this.optionsVisibility(this.state.options, this.props.videoElement.currentTime);
        this.setState({...this.state, options: options});
    };

    videoEnded = (data) => {
    };

    render() {
        let option_texts = [];
        let option_circles = [];

        this.state.options.forEach((option) => {
            if (!option.visible) return;

            switch (option.properties.type) {
                case 'text':
                    option_texts.push(
                        <button key={option.id}
                                onClick={() => { this.props.sendInput({input: { type: 'answer', answer: option.id, time: this.props.videoElement.currentTime }}) }}>
                            { option.properties['text'] }
                        </button>
                    );
                    return;

                case 'circle':
                    option_circles.push(
                        <button key={option.id} className={'option-circle'}
                                style={{position: 'absolute', top: `${option.properties.y}%`, left: `${option.properties.x}%`, transform: 'translate(-50%, -50%)' }}
                                onClick={() => { this.props.sendInput({input: { type: 'answer', answer: option.id, time: this.props.videoElement.currentTime }}) }}>
                        </button>
                    );
                    return;

                default:
                    return;
            }
        });

        return (
            <div id='overlay-pick-answer'>
                <div className='player-controls float-left'>{ this.props.modalToggle }</div>
                <div className='player-controls'>{ this.props.fullscreenToggle() }</div>
                {
                    ( this.props.debug || ( option_circles.length === 0 && option_texts.length === 0 ) )&&
                    <div className='player-controls'>
                        <div>{ this.state.videoState === 'paused' ? <i className="control-item fas fa-play" onClick={() => { this.props.play() }}/> : '' }</div>
                        <div>{ this.state.videoState === 'playing' ? <i className="control-item fas fa-pause"  onClick={() => { this.props.pause() }}/> : '' }</div>
                    </div>
                }
                {
                    this.props.debug && <div><button onClick={() => { this.props.sendInput({input: { type: 'reinit_node' }}) }}>reinit_node</button></div>
                }
                {
                    this.props.debug && <div><button onClick={() => { this.props.sendInput({input: { type: 'next', time: this.props.videoElement.currentTime }}) }}>next</button></div>
                }
                { option_circles }
                <div className='options-text'>
                    <TimeProgress videoElement={this.props.videoElement} timeProgress={this.state.timeProgress} />
                    { option_texts }
                </div>
            </div>
        )
    };

    timeProgressOptions(options) {
        let show = null, hide = 0;

        options.forEach((option) => {
            if (option.properties && option.properties.show_at && ( show === null || show > option.properties.show_at )) show = option.properties.show_at;
            if (option.properties && option.properties.hide_at && ( hide < option.properties.hide_at )) hide = option.properties.hide_at;
        });

        if (show === null) show = 0;

        return { show: show, hide: hide };
    }

    processOptions(props) {
        let state = props.gameState.state;
        let properties = state['properties'];
        let options = [...(properties['current_options'] || [])];

        return this.optionsVisibility(options, props.videoElement.currentTime);
    }

    optionsVisibility(options, timestamp) {
        return options.map((option) => {
            let show_at = (option['properties']['show_at'] || 0);
            let hide_at = option['properties']['hide_at'];

            option['visible'] = (show_at <= timestamp) && (!hide_at || (hide_at >= timestamp)) && (option['name'] !== 'timeout');
            return option;
        })
    }
}

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendInput: sendInput
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(NodesPickAnswer));