import * as types from "./types";
import {RSAA} from "redux-api-middleware";

const defaultHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json' };

export const createGame = (id) => {

    return {
        [RSAA]: {
            endpoint: `${process.env.API_HOST}/api/create`,
            method: 'POST',
            body: JSON.stringify({id: id}),
            headers: defaultHeaders,
            types: [
                types.CREATE_GAME,
                types.CREATE_GAME_SUCCESS,
                types.CREATE_GAME_ERROR
            ],
            credentials: 'same-origin'
        }
    };

};

export const loadGameState = () => {

    return {
        [RSAA]: {
            endpoint: `${process.env.API_HOST}/api/instance`,
            method: 'POST',
            headers: defaultHeaders,
            types: [
                types.LOAD_GAME_STATE,
                types.LOAD_GAME_STATE_SUCCESS,
                types.LOAD_GAME_STATE_ERROR
            ],
            credentials: 'same-origin'
        }
    };

};

function sendInputData(input) {
    return {
        [RSAA]: {
            endpoint: `${process.env.API_HOST}/api/input`,
            method: 'POST',
            body: JSON.stringify(input),
            headers: defaultHeaders,
            types: [
                types.SEND_INPUT,
                types.SEND_INPUT_SUCCESS,
                types.SEND_INPUT_ERROR
            ],
            credentials: 'same-origin'
        }
    };
}

export const sendInput = (input) => {
    return function (dispatch, getState) {
        if (!getState().gameState.sendingInput) {
            return dispatch(sendInputData(input))
        }
    }
};
