import React from "react"
import {createGame, sendInput} from "../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class CloseOverlay extends React.Component {

    constructor(params) {
        super(params);
    }

    resumeGame = () => {
        this.props.play();
        this.props.closeModalToggle();
    };

    exitGame = () => {
        this.props
            .sendInput({input: { type: 'exit' }})
            .then(() => {
                this.props.createGame();
            });
        this.props.closeVideoPlayer();
    };

    render() {
        return (
            <div id='overlay-close' className='overlay-layout justify-content-center'>
                <div className='text-center'>
                        <p className='m-4'>Pokud si zvolíš odchod, přijdeš o svůj dosavadní postup a začneš JobVentura 2 znova od začátku.</p>
                        <button className='btn btn-primary m-3' onClick={this.resumeGame}>Zůstat</button>
                        <button className='btn btn-primary m-3' onClick={this.exitGame}>Odejít</button>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        sendInput: sendInput,
        createGame: createGame
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CloseOverlay));
