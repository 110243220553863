import React from "react"
import {IndexRoute, Route, Link, Switch, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Fullscreen from "react-full-screen";
import {isMobile} from "react-device-detect";
import {createGame, loadGameState, sendInput} from "./actions";
import VideoPlayer from "./components/video-player";
import {Modal, ModalBody} from "reactstrap";
import images from "./assets";
import * as qs from "query-string";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            debug: false,
            open: false,
            isFull: false,
            width: 0,
            height: 0,
            overlayStyle: { width: 0, height: 0 }
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    open = () => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false });
    };

    goFull = () => {
        this.setState({...this.state, isFull: true });
    };

    goNormal = () => {
        this.setState({...this.state, isFull: false });
    };

    componentDidMount() {
    }

    updateWindowDimensions() {
        this.setState({...this.state, width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        let containerClass = this.state.isFull ? 'fullscreen' : 'normal';
        const query = qs.parse(window.location.search);
        const debug = query['debug'] === 'true';

        return (
            <div>
                <span id="main-player-link" className="player-link" onClick={this.open}>
                    <img src={images.jv_2} className='img-fluid'/>
                    <div className="player-link__overlay">
                        <div className="player-link__overlay__button">
                      <span className="nav-icon"><i className="fas fa-play"/></span>
                    </div>
                        <div className="player-link__overlay__episode">Jobventura 2</div>
                        <div className="player-link__overlay__title">Vyraz za kariérou</div>
                    </div>
                </span>
                <Modal size='lg' isOpen={this.state.open} id='video-player-modal'>
                    <ModalBody>
                        <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({isFull})}>
                            <VideoPlayer
                                fullscreen={this.state.isFull}
                                goFullscreen={this.goFull}
                                goNormal={this.goNormal}
                                gameState={this.props.gameState}
                                close={this.close}
                                debug={debug}
                            />
                        </Fullscreen>
                        {
                            debug &&
                            <div className=''>
                                <button onClick={() => { this.props.createGame(1) }}>new game</button> |
                                <button onClick={() => { this.props.sendInput({input: { type: 'win' }}); }}>win</button> |
                                <button onClick={() => { this.props.sendInput({input: { type: 'lose' }}); }}>lose</button> |
                                <button onClick={() => { this.setState({ debug: !this.state.debug }); }}>debug</button>
                                {
                                    this.state.debug &&
                                    <div>
                                        <p>state:</p>
                                        <pre style={{wordWrap: 'break-word'}}>{JSON.stringify(this.props.gameState.state, null, 2)}</pre>
                                    </div>
                                }
                            </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    };
};

function mapStateToProps(state,ownProps) {
    return {
        gameState: state.gameState
    };
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        createGame: createGame,
        sendInput: sendInput,
        loadGameState: loadGameState
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));