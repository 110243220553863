import { push } from 'react-router-redux'
import {modalShow} from "./actions";
import * as types from "./actions/types";
import * as ReactGA from "react-ga";

let currentState;
let reduxStore;

function handleChange() {
    let previousState = currentState;
    currentState = reduxStore.getState().gameState.state;

    if (!currentState)
        return;

    let currentNode = currentState['current_node'];

    if (currentNode) {
        switch (currentNode['type']) {
            case 'Luduengine::Game::Nodes::Screen':
                let screenName = currentNode['name'];
                reduxStore.dispatch(push(`/game/screen/${screenName}`));
                break;

            case 'Nodes::Init':
                reduxStore.dispatch(push(`/game/screen/init`));
                break;

            case 'Nodes::PickAnswer':
                reduxStore.dispatch(push(`/game/nodes-pick-answer`));
                break;

            case 'Nodes::Checkpoint':
                reduxStore.dispatch(push(`/game/checkpoint`));
                break;

            case 'Nodes::LostDashboard':
                reduxStore.dispatch(push(`/game/screen/lost`));
                break;

            case 'Nodes::WinningScreen':
                reduxStore.dispatch(push(`/game/screen/winning`));
                break;

            default:
                break;
        }
    }
}

export default function subscribe(store) {
    reduxStore = store;
    reduxStore.subscribe(handleChange);
};
