import nadace from '../images/nadace.png';
import nadace_white from '../images/ntm_white.png'
import accenture from '../images/accenture.png';
import accenture_white from '../images/accenture_white.png'
import jv_1 from '../images/jv_1.jpg';
import jv_2 from '../images/jv_2.jpg';
import bg_jv_2 from '../images/bg-jv2.jpg';
import jobventura from '../images/jobventura.svg';
import pic_1 from '../images/authors/Pic_1.jpg';
import pic_2 from '../images/authors/Pic_2.jpg';
import pic_3 from '../images/authors/Pic_3.jpg';
import pic_4 from '../images/authors/Pic_4.jpg';
import pic_5 from '../images/authors/Pic_5.jpg';
import pic_6 from '../images/authors/Pic_6.jpg';
import pic_7 from '../images/authors/Pic_7.jpg';
import pic_8 from '../images/authors/Pic_8.jpg';
import pic_9 from '../images/authors/Pic_9.jpg';
import pic_10 from '../images/authors/Pic_10.jpg';
import pic_11 from '../images/authors/Pic_11.jpg';
import pic_12 from '../images/authors/Pic_12.jpg';
import pic_13 from '../images/authors/Pic_13.jpg';
import pic_14 from '../images/authors/Pic_14.jpg';
import pic_15 from '../images/authors/Pic_15.jpg';
import pic_16 from '../images/authors/Pic_16.jpg';

const assets = {
    jobventura,
    nadace,
    nadace_white,
    accenture,
    accenture_white,
    jv_1,
    jv_2,
    bg_jv_2,
    pic_1,
    pic_2,
    pic_3,
    pic_4,
    pic_5,
    pic_6,
    pic_7,
    pic_8,
    pic_9,
    pic_10,
    pic_11,
    pic_12,
    pic_13,
    pic_14,
    pic_15,
    pic_16
};

export default assets;
