import * as types from '../actions/types';

const initState = {
    state: null,

    creating: false,
    creatingError: null,

    loadingState: false,
    loadingError: null,

    sendingInput: false,
    sendingInputError: null
};

export default function GameStateReducer (state = initState, action) {

    switch (action.type)
    {
        case types.CREATE_GAME:
            return { ...state, creating: true, creatingError: null };

        case types.CREATE_GAME_SUCCESS:
            return { ...state, state: action.payload, creating: false, creatingError: null };

        case types.CREATE_GAME_ERROR:
            return { ...state, creating: false, creatingError: action.payload };

        case types.LOAD_GAME_STATE:
            return { ...state, loadingState: true, loadingError: null };

        case types.LOAD_GAME_STATE_SUCCESS:
            return { ...state, loadingState: false, loadingError: null, state: action.payload };

        case types.LOAD_GAME_STATE_ERROR:
            return { ...state, loadingState: false, loadingError: action.payload };

        case types.SEND_INPUT:
            return { ...state, sendingInput: true, sendingInputError: null };

        case types.SEND_INPUT_SUCCESS:
            return { ...state, sendingInput: false, sendingInputError: null, state: action.payload };

        case types.SEND_INPUT_ERROR:
            return { ...state, sendingInput: false, sendingInputError: action.payload };

        default:
            return state;
    }

}
