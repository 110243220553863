export const CREATE_GAME = 'CREATE_GAME';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_ERROR = 'CREATE_GAME_ERROR';

export const LOAD_GAME_STATE = 'LOAD_GAME_STATE';
export const LOAD_GAME_STATE_SUCCESS = 'LOAD_GAME_STATE_SUCCESS';
export const LOAD_GAME_STATE_ERROR = 'LOAD_GAME_STATE_ERROR';

export const SEND_INPUT = 'SEND_INPUT';
export const SEND_INPUT_SUCCESS = 'SEND_INPUT_SUCCESS';
export const SEND_INPUT_ERROR = 'SEND_INPUT_ERROR';